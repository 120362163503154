import { h, render } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import "./assets/main.css";
import { AuthClient } from "@dfinity/auth-client";

import icp from "./assets/icp_100_chain.svg";
import d_icon from "./assets/dragginz_icon_vectors_blue.svg";
import d_logo from "./assets/dragginz_logo_lower_blue.svg";
import { MyStorage } from "./Storage";

const App = () => {
  const [connected, setConnected] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [identityProvider, setIdentityProvider] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonHidden, setIsButtonHidden] = useState(false);

  const webSocketRef = useRef(null);

  useEffect(() => {
    setupBrowserCheck();
    setupWebSocket();
    setIdentityProvider(setupIdentityProvider());
    if (!isChrome) {
      checkConnection();
    }

    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, [isChrome]);

  const setupIdentityProvider = () => {
    const url = new URL(window.location.href);
    const localhost = url.searchParams.get("localhost");
    return localhost
      ? "http://rdmx6-jaaaa-aaaaa-aaadq-cai.localhost:4943/#authorize"
      : "https://identity.ic0.app/#authorize";
  };

  const setupBrowserCheck = () => {
    const isChromium = !!window.chrome;
    const isEdge = navigator.userAgent.includes("Edg");

    const chromeStatus = isChromium && !isEdge;
    setIsChrome(chromeStatus);
    setIsButtonDisabled(!chromeStatus);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setIsButtonHidden(true);
    await getIdentity();
  };

  const setupWebSocket = () => {
    const webSocket = new WebSocket("ws://localhost:8080/data");

    webSocket.addEventListener("open", () => {
      console.log("WebSocket connection established.");
      setConnected(true);
    });

    webSocket.addEventListener("close", () => {
      console.log("WebSocket closed.");
    });

    webSocket.addEventListener("error", (e) => {
      console.error(e);
    });

    webSocket.addEventListener("message", (event) => {
      if (event.data === "Done") {
        console.log("Closing WebSocket.");
        webSocket.close();
        window.close();
      }
    });

    webSocketRef.current = webSocket;
  };

  const checkConnection = () => {
    console.log("Checking connection...");
    if (!connected) {
      setTimeout(checkConnection, 1000);
    } else {
      getIdentity();
    }
  };

  const sendMessage = (message) => {
    if (webSocketRef.current && connected) {
      webSocketRef.current.send(message);
    } else {
      console.log("WebSocket is not connected or not initialized.");
    }
  };

  const getIdentity = async () => {
    console.log("Getting identity...");
    try {
      const storage = new MyStorage("LiveStorage");
      const authClient = await AuthClient.create({ storage, keyType: "Ed25519" });

      const isAuthenticated = await authClient.isAuthenticated();
      if (isAuthenticated) {
        console.log("User is authenticated.");
        const identity = await authClient.getIdentity();
        sendMessage(JSON.stringify(identity));
      } else {
        console.log("User is not authenticated, proceeding to login.");
        await handleLogin(authClient);
      }
    } catch (error) {
      console.error("Error in getIdentity:", error);
    }
  };

  const handleLogin = async (authClient) => {
    try {
      await authClient.login({
        identityProvider: identityProvider || "",
        onSuccess: async () => {
          console.log("Login successful.");
          const identity = await authClient.getIdentity();
          sendMessage(JSON.stringify(identity));
        },
        onError: (error) => {
          console.error("Login failed:", error);
          setIsButtonDisabled(false);
          setIsButtonHidden(false);
        },
      });
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div>
      
      <div className="centered-container">
        <img className="icon-dkp" src={d_icon} alt="Logo" />
        <img src={d_logo} alt="Logo" />
      </div>
      <p className="text-d">
        Press Continue to login with your Internet Identity.
      </p>
      <p className="text-d">This website is safe.</p>
      <div className="centered-container">
        <img className="icp-logo" src={icp} alt="Logo" />
        {!isChrome && (
        <div className="alert">
          Note: Dragginz currently requires Chrome browser to log in.  Please set Chrome as the default browser in Settings and try again.
        </div>
        )}
        <button
          id="click"
          className="btn"
          onClick={handleClick}
          disabled={isButtonDisabled}
          hidden={isButtonHidden}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

render(<App />, document.getElementById("app"));
